<template>
	<HeroPage v-if="pageData" :data="pageData" />
</template>

<script>
/* eslint-disable no-unused-vars */
import { fetchPageBySlug } from "@streampac.io/chef_sp_1";

// @import component
import HeroPage from "@/components/HeroPage";

export default {
	name: "PaymentSuccess",
	inject: ["isAuthenticated"],
	data() {
		return {
			pageData: null,
		};
	},
	components: {
		HeroPage,
	},
	created() {
		this.fetchPage();
	},
	methods: {
		fetchPage() {
			fetchPageBySlug(this.isAuthenticated, "payment-failed").then(
				(response) => {
					this.pageData = response;
				}
			);
		},
	},
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
